import React, { FC } from 'react';
import { Profile } from '@modules/auth/model';

import * as Styled from './Layout.styles';
import Nav from './nav/Nav';

interface LayoutProps {
  profile: Profile;
}

const Layout: FC<LayoutProps> = ({ profile, children }) => {
  return (
    <Styled.LayoutContainer>
      <Nav profile={profile} />
      <Styled.LayoutContent>{children}</Styled.LayoutContent>
    </Styled.LayoutContainer>
  );
};

export default Layout;
