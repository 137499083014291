import React, { FC } from 'react';
import { Profile } from '@modules/auth/model';

import * as Styled from './Nav.styles';

import logo from '@assets/logos/citymag.svg';
import { Button } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { useAuthContext } from '@modules/auth/context';

interface NavEntry {
  to: string;
  title: string;
}

const entries: Array<NavEntry> = [
  {
    to: '/platform',
    title: 'Plateforme',
  },
  {
    to: '/cities',
    title: 'Villes',
  },
];

interface NavProps {
  profile: Profile;
}

const Nav: FC<NavProps> = ({ profile }) => {
  const { logout } = useAuthContext();

  return (
    <Styled.NavContainer>
      <Styled.NavHeader to="/">
        <img src={logo} alt="Citymag" />
      </Styled.NavHeader>

      <Styled.NavContent>
        <Styled.NavList>
          {entries.map(entry => (
            <li key={entry.to}>
              <NavLink to={entry.to}>{entry.title}</NavLink>
            </li>
          ))}
        </Styled.NavList>
      </Styled.NavContent>

      <Styled.NavUser>
        <p>@{profile.login}</p>

        <Button icon="sign out alternate" size="small" basic onClick={logout} />
      </Styled.NavUser>
    </Styled.NavContainer>
  );
};

export default Nav;
