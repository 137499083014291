import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpError, HttpRange, HttpTask } from '@core/http/model';
import { Lazy, pipe } from 'fp-ts/function';

import * as TE from 'fp-ts/TaskEither';
import { defaultAxiosInstance } from '@core/http/config';
import { Range, RangeCursor, RangeResult } from '@shared/modules/range';
import { Filter } from '@shared/modules/filter';

function onError<E = unknown>(err: unknown): HttpError<E> {
  return HttpError.fromAxiosError<E>(err as AxiosError);
}

function transformRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>): HttpTask<R, E> {
  return pipe(
    TE.tryCatch(request, err => onError<E>(err)),
    TE.map(res => res.data),
  );
}

function get<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E> {
  return transformRequest(() => defaultAxiosInstance.get(url, config));
}

function getRange<R = unknown, F extends Filter = {}, E = unknown>(
  url: string,
  cursor: RangeCursor,
  filter?: F,
  config?: AxiosRequestConfig,
): HttpRange<R, F, E> {
  return pipe(
    get<RangeResult<R, F>, E>(url, {
      ...config,
      params: {
        ...config?.params,
        ...cursor,
        ...filter,
      },
    }),
    TE.map(Range.fromRangeResult),
  );
}

function post<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E> {
  return transformRequest(() => defaultAxiosInstance.post(url, data, config));
}

function del<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E> {
  return transformRequest(() => defaultAxiosInstance.delete(url, config));
}

export const httpService = {
  get,
  getRange,
  post,
  delete: del,
};
