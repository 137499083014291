import { pipe } from 'fp-ts/function';
import theme from '@styles/theme';

import * as R from 'fp-ts/Record';
import { DefaultTheme, ThemeProps } from 'styled-components';

import { rgba as polishedRgba } from 'polished';

export const colors = pipe(
  theme.colors,
  R.reduceWithIndex(
    {} as Record<keyof typeof theme.colors, (props: ThemeProps<DefaultTheme>) => string>,
    (key, acc) => ({
      ...acc,
      [key]: (props: ThemeProps<DefaultTheme>) => props.theme.colors[key],
    }),
  ),
);

export function rgba(
  colorFn: (props: ThemeProps<DefaultTheme>) => string,
  amount: number,
): (props: ThemeProps<DefaultTheme>) => string {
  return props => polishedRgba(colorFn(props), amount);
}
