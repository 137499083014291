import React, { FC } from 'react';
import { Routes, safeLazy } from '@core/router';
import { Redirect, Route } from 'react-router-dom';
import { useAuthContext } from '@modules/auth/context';
import { renderOptional } from '@shared/utils/render';
import { Profile } from '@modules/auth/model';
import Layout from '@layout/Layout';

const AuthRoutes = safeLazy(() => import('@modules/auth/routes'));

const PlatformRoutes = safeLazy(() => import('@modules/platform/routes'));
const CitiesRoutes = safeLazy(() => import('@modules/cities/routes'));

interface PrivateRoutesProps {
  profile: Profile;
}

const PrivateRoutes: FC<PrivateRoutesProps> = ({ profile }) => (
  <Layout profile={profile}>
    <Routes>
      <Redirect path="/" to="/cities" exact />
      <Route path="/platform" component={PlatformRoutes} />
      <Route path="/cities" component={CitiesRoutes} />
    </Routes>
  </Layout>
);

const RootRoutes: FC = () => {
  const { profile } = useAuthContext();

  return (
    <Routes>
      <Route path="/login" component={AuthRoutes} />

      {renderOptional(
        profile,
        profile => (
          <Route path="/">
            <PrivateRoutes profile={profile} />
          </Route>
        ),
        () => (
          <Redirect
            to={{
              pathname: '/login',
              state: { referrer: window.location.pathname },
            }}
          />
        ),
      )}
    </Routes>
  );
};

export default RootRoutes;
