import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: hidden;
  }
  
  #root {
    height: 100%;
    overflow: hidden;
  }
  
  .sticky-filter {
    > div {
      transform: none !important;
    }
  }
`;
