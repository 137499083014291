import { createGlobalStyle } from 'styled-components';
import { colors } from '@styles/utils';

export const ToastsStyles = createGlobalStyle`
  .Toastify__toast-container {
    z-index: 100000 !important;
    
    .citymag-toast {
      min-height: 50px;
      padding: 10px;
      border-radius: 10px;
      
      &.Toastify__toast--success {
        --toastify-icon-color-success: #fff;
        background: ${colors.primary};
        color: #fff;
      }
      
      &.Toastify__toast--error {
        --toastify-icon-color-error: #fff;
        background: ${colors.coral};
        color: #fff;
      }
    }
  }
`;
