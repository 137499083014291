import styled from 'styled-components';
import { colors } from '@styles/utils';
import { Link } from 'react-router-dom';

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
`;

export const NavHeader = styled(Link)`
  display: flex;
  flex: 0 0 55px;
  align-items: center;
  padding: 0 15px;
  background: ${colors.white};

  > img {
    height: 40px;
  }
`;

export const NavContent = styled.div`
  flex: 1 1 auto;
  padding: 15px 10px;
  background: ${colors.secondary};
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    display: flex;
    flex: 0 0 30px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    > a {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      height: 30px;
      padding: 0 10px;
      color: ${colors.white};
      font-weight: 600;
      font-size: 16px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0);
      transition: background-color 0.15s ease-in-out;

      &.active,
      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }
`;

export const NavUser = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 50px;
  padding: 0 5px 0 10px;
  background: ${colors.white};

  > p {
    flex: 1 1 auto;
    font-weight: 600;
    margin: 0;
  }
`;
