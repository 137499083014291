import React, { ReactNode } from 'react';

import * as O from 'fp-ts/Option';
import * as RD from 'fp-ts-remote-data';
import { constNull, pipe } from 'fp-ts/function';
import { HttpError, HttpRemoteData } from '@core/http';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import ErrorPage from '@shared/components/error-page/ErrorPage';

export function renderOptional<T, R extends ReactNode>(
  value: O.Option<T>,
  onSome: (data: T) => R,
  onNone: () => R | null = constNull,
): R | null {
  return pipe(value, O.fold(onNone, onSome));
}

export function renderNullable<T, R extends ReactNode>(
  value: T | null | undefined,
  onSome: (data: T) => R,
  onNone: () => R | null = constNull,
): R | null {
  return renderOptional(O.fromNullable(value), onSome, onNone);
}

export function renderHttpRemoteData<E, A, R extends ReactNode>(
  value: HttpRemoteData<A, E>,
  onSuccess: (data: A) => R,
  onLoading: () => R | JSX.Element = () => <DebouncedLineLoader />,
  onError: (error: HttpError<E>) => R | JSX.Element = error => <ErrorPage error={error} />,
): R | JSX.Element {
  return pipe(value, RD.fold(onLoading, onSuccess, onError));
}
