import { httpService, HttpTask } from '@core/http';
import { Profile } from '@modules/auth/model';
import { pipe } from 'fp-ts/function';

import * as TE from 'fp-ts/TaskEither';

export function getProfile(): HttpTask<Profile> {
  return httpService.get('/profile');
}

export function authenticate(code: string): HttpTask<Profile> {
  return pipe(httpService.post(`/authenticate/${code}`), TE.chain(getProfile));
}

export function logout(): HttpTask {
  return httpService.post('/logout');
}
