import styled from 'styled-components';
import { Tab } from 'semantic-ui-react';
import { colors } from '@styles/utils';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PageTopBar = styled.div`
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 0 25px;
  background: ${props => props.theme.colors.white};
  border-bottom: 1px solid #ddd;

  .ui.breadcrumb {
    a.section {
      color: ${colors.secondary};
    }

    .section {
      font-weight: 600;
    }
  }
`;

export const PageTopBarLeft = styled.div`
  flex: 1 1 auto;

  .breadcrumb .section {
    display: inline-flex;
    align-items: center;
  }
`;

export const PageToBarRight = styled.div`
  flex: 1 1 auto;
  text-align: right;
`;

export const PageContent = styled.div`
  flex: 1 1 auto;
  padding: 20px 15px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const PageBottomBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 50px;
  padding: 0 10px;
  background: ${props => props.theme.colors.white};
  border-top: 1px solid #ddd;

  .button {
    margin-right: 7px !important;
  }
`;

export const PageBottomBarLeft = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 0;
`;

export const PageBottomBarRight = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
  padding: 7px 0;
`;

export const PageTabs = styled(Tab)`
  .ui.pointing.secondary.menu {
    background: ${props => props.theme.colors.white};

    > a.item {
      padding: 16px 25px;
      font-weight: 500;

      &.active {
        border-color: ${colors.secondary};
      }
    }
  }
`;
