import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '@styles/theme';
import { Router } from 'react-router-dom';
import RootRoutes from './routes';
import { GlobalStyles } from '@styles/global';
import { AuthContextProvider } from '@modules/auth/context';
import ToastsProvider from '@shared/components/toasts/ToastsProvider';
import history from './app-history';
import { HelmetProvider } from 'react-helmet-async';

const App: FC = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <ToastsProvider />
    <HelmetProvider>
      <AuthContextProvider>
        <Router history={history}>
          <RootRoutes />
        </Router>
      </AuthContextProvider>
    </HelmetProvider>
  </ThemeProvider>
);

export default App;
