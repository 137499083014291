import styled, { css } from 'styled-components';
import { colors, shouldForwardPropHelper } from '@styles/utils';
import { rgba } from 'polished';

export const VirtualizedTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid ${rgba('#cacbce', 0.5)};
`;

export const VirtualizedTableContent = styled.div`
  position: relative;
  flex: 1 1 auto;
`;

export const VirtualizedTableRowWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    shouldForwardPropHelper(prop, defaultValidatorFn, ['loading', 'hover']),
})<{ loading?: boolean; hover?: boolean }>`
  background: ${colors.white};
  font-size: 14px;
  color: ${colors.black};
  text-decoration: none;

  &:not(:last-child) {
    border-bottom: 1px solid ${rgba('#cacbce', 0.5)};
  }

  ${props =>
    props.loading &&
    css`
      padding: 0 15px;
    `}

  ${props =>
    props.hover &&
    css`
      transition: background 0.15s linear;

      &:hover {
        background: #fbfbfb;
        color: ${colors.black};
        cursor: pointer;
      }
    `}
`;

export const VirtualizedTableNoRow = styled(VirtualizedTableRowWrapper)`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  will-change: unset;
`;
