import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#0db8ab',
    secondary: '#07379D',
    coral: '#FF6D70',
    light: '#f7f7f7',
    white: '#ffffff',
    dark: '#31333e',
    black: '#000000',
  },
};

export default theme;
